import './front.scss';

$(document).ready(function () {
    if ($('.featured-products .thumbnail-top').length >= 1) {
        $('.featured-products .thumbnail-top').slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            lazyLoad: 'ondemand',
            slidesToShow: 1,
            slidesToScroll: 1,
        })
    }
    if ($('.product-accessories .thumbnail-top').length >= 1) {
        $('.product-accessories .thumbnail-top').slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            lazyLoad: 'ondemand',
            slidesToShow: 1,
            slidesToScroll: 1,
        })
    }
});

$(document).ready(() => {
    moveCustomizationMobile();

    function moveCustomizationMobile() {
        if ($(window).width() < 960) {
            // Cela déplace le champs de customization (pour le mobile)
            if ($('.bloc_custom').length > 0) {
                $('.product-customization').appendTo('.bloc_custom');
            }
        }
    }
});
$(document).on({
    change: function () {
        if ($(this).val() != 0) {
            var nameSelect = $(this).attr("name");
            $.post($(this).data('action'), {'submitCustomizedData': true, 'selectFieldId' : nameSelect, 'value' : $(this).val()})
                .then((resp) => {
                    location.reload();

                    if ($(this).find('option:selected').text() !== $('.customization-select label').text()) {
                        $('.customization-select label').text($(this).find('option:selected').text());
                    }
                });
        } else {
            prestashop.emit('showErrorNextToAddtoCartButton', {errorMessage: 'Veuillez sélectionner un spectacle'});
            return;
        }
    }
}, '.select_customization');